import {
  getDataFromApi,
  getDataFromListApi,
  getlookupFromApi,
  getLookupRegioniProvincie,
  getDataFromListApiGet,
  getLookupGiornoDisponibili,
  getLookupFasi,
  getLookupRuoli,
} from "@/axios/apiRequest";
export default {
  state: {},
  getters: {
    getStateFromName: (state) => (stateName) => {
      return state[stateName];
    },
  },
  actions: {
    getListFromApi: async (stateObj, { keys, itemName, noTot }) => {
      const { state } = stateObj;
      state[`loaded${itemName}`] = false;
      state[`results${itemName}`].splice(0, state[`results${itemName}`].length);
      keys = {
        ...keys,
        rowstoskip: state[`rowstoskip${itemName}`],
        fetchrows: state[`fetchrows${itemName}`],
        sortcolumn: state[`sortcolumn${itemName}`],
        sortorder: state[`sortorder${itemName}`],
      };
      if (state[`isLoading${itemName}`]) return;
      state[`isLoading${itemName}`] = true;
      const res = await getDataFromListApi(keys, state[`apiLink${itemName}`]);
      const { list, status, record,message } = res;
      if (status === 200 && list && list.length) {
        list.forEach((element) => {
          state[`results${itemName}`].push(element);
        });
        state[`status${itemName}`] = status;
        state[`loaded${itemName}`] = true;
      } else if (status === 200 && (!list || !list.length)) {
        state[`status${itemName}`] = 204;
        state[`loaded${itemName}`] = true;
      } else {
        state[`status${itemName}`] = status;
        state[`loaded${itemName}`] = true;
        state[`message${itemName}`] = message;
      }
      // state[`results${itemName}`] = list;
      state[`isLoading${itemName}`] = false;
      state[`record${itemName}`] = noTot
        ? list && list.length
          ? list.length
          : 0
        : record;
    },
    getListFromApiGet: async (stateObj, { keys, itemName }) => {
      const { state } = stateObj;
      state[`loaded${itemName}`] = false;
      state[`results${itemName}`].splice(0, state[`results${itemName}`].length);
      keys = {
        ...keys,
        rowstoskip: state[`rowstoskip${itemName}`],
        fetchrows: state[`fetchrows${itemName}`],
        sortcolumn: state[`sortcolumn${itemName}`],
        sortorder: state[`sortorder${itemName}`],
      };
      if (state[`isLoading${itemName}`]) return;
      state[`isLoading${itemName}`] = true;
      const res = await getDataFromListApiGet(
        keys,
        state[`apiLink${itemName}`]
      );
      const { list, status, record } = res;
      if (status === 200 && list.length) {
        list.forEach((element) => {
          state[`results${itemName}`].push(element);
        });
        state[`status${itemName}`] = status;
        state[`loaded${itemName}`] = true;
      } else if (status === 200 && !list.length) {
        state[`status${itemName}`] = 204;
        state[`loaded${itemName}`] = true;
      } else {
        state[`status${itemName}`] = status;
        state[`loaded${itemName}`] = true;
      }
      // state[`results${itemName}`] = list;
      state[`isLoading${itemName}`] = false;
      state[`record${itemName}`] = record;
    },
    setStoreListData: async (
      stateObj,
      {
        keys,
        apiLink,
        itemName,
        splice,
        noLoading,
        noTot,
        sortcolumn,
        sortorder,
        noCallApi,
      }
    ) => {
      const { state } = stateObj;
      state[`currentPage${itemName}`] = state[`currentPage${itemName}`]
        ? state[`currentPage${itemName}`]
        : 1;
      state[`rowstoskip${itemName}`] = state[`rowstoskip${itemName}`]
        ? state[`rowstoskip${itemName}`]
        : 0;
      state[`fetchrows${itemName}`] = state[`fetchrows${itemName}`]
        ? state[`fetchrows${itemName}`]
        : 20;
      state[`sortcolumn${itemName}`] = state[`sortcolumn${itemName}`]
        ? state[`sortcolumn${itemName}`]
        : sortcolumn;
      state[`sortorder${itemName}`] = state[`sortorder${itemName}`]
        ? state[`sortorder${itemName}`]
        : sortorder;
      state[`apiLink${itemName}`] = state[`apiLink${itemName}`]
        ? state[`apiLink${itemName}`]
        : apiLink;
      keys = {
        rowstoskip: noTot ? undefined : state[`rowstoskip${itemName}`],
        fetchrows: noTot ? undefined : state[`fetchrows${itemName}`],
        sortcolumn: noTot ? undefined : state[`sortcolumn${itemName}`],
        sortorder: noTot ? undefined : state[`sortorder${itemName}`],
        ...keys,
      };
      if (state[`isLoading${itemName}`]) return;
      if (
        state[`results${itemName}`] != undefined &&
        state[`results${itemName}`].length
      ) {
        state[`loaded${itemName}`] = true;
        return;
      }
      state[`isLoading${itemName}`] = true;
      if (!noLoading) state[`loaded${itemName}`] = false;
      if (state[`results${itemName}`] === undefined) {
        state[`results${itemName}`] = [];
      }
      if (splice)
        state[`results${itemName}`].splice(
          0,
          state[`results${itemName}`].length
        );
      if (!noCallApi) {
        const res = await getDataFromListApi(keys, apiLink);
        const { list, status, record } = res;

        if (status === 200 && list && list.length) {
          list.forEach((element) => {
            state[`results${itemName}`].push(element);
          });
          state[`status${itemName}`] = status;
          state[`loaded${itemName}`] = true;
        } else if (status === 200 && (!list || !list.length)) {
          state[`status${itemName}`] = 204;
          state[`loaded${itemName}`] = true;
        } else {
          state[`status${itemName}`] = status;
          state[`loaded${itemName}`] = true;
        }
        // state[`results${itemName}`] = list;
        state[`isLoading${itemName}`] = false;
        state[`record${itemName}`] = noTot
          ? list && list.length
            ? list.length
            : 0
          : record;
      } else {
        state[`isLoading${itemName}`] = false;
        state[`record${itemName}`] = 0;
        state[`loaded${itemName}`] = true;
      }
    },
    setStoreListDataGet: async (
      stateObj,
      { keys, apiLink, itemName, splice, noLoading, sortcolumn, sortorder }
    ) => {
      const { state } = stateObj;
      state[`currentPage${itemName}`] = 1;
      state[`rowstoskip${itemName}`] = 0;
      state[`fetchrows${itemName}`] = 20;
      state[`sortcolumn${itemName}`] = sortcolumn;
      state[`sortorder${itemName}`] = sortorder;
      state[`apiLink${itemName}`] = apiLink;
      keys = {
        rowstoskip: state[`rowstoskip${itemName}`],
        fetchrows: state[`fetchrows${itemName}`],
        sortcolumn: state[`sortcolumn${itemName}`],
        sortorder: state[`sortorder${itemName}`],
        ...keys,
      };
      if (state[`isLoading${itemName}`]) return;
      if (
        state[`results${itemName}`] != undefined &&
        state[`results${itemName}`].length
      ) {
        state[`loaded${itemName}`] = true;
        return;
      }
      state[`isLoading${itemName}`] = true;
      if (!noLoading) state[`loaded${itemName}`] = false;
      if (state[`results${itemName}`] === undefined) {
        state[`results${itemName}`] = [];
      }
      if (splice)
        state[`results${itemName}`].splice(
          0,
          state[`results${itemName}`].length
        );
      const res = await getDataFromListApiGet(keys, apiLink);
      const { list, status, record } = res;
      if (status === 200 && list.length) {
        list.forEach((element) => {
          state[`results${itemName}`].push(element);
        });
        state[`status${itemName}`] = status;
        state[`loaded${itemName}`] = true;
      } else if (status === 200 && !list.length) {
        state[`status${itemName}`] = 204;
        state[`loaded${itemName}`] = true;
      } else {
        state[`status${itemName}`] = status;
        state[`loaded${itemName}`] = true;
      }
      // state[`results${itemName}`] = list;

      state[`isLoading${itemName}`] = false;
      state[`record${itemName}`] = record;
    },
    setStoreData: async (stateObj, { keys, apiLink, itemName }) => {
      const { state } = stateObj;
      // carica la lookup nello state con il nome che arriva da api
      const res = await getDataFromApi(keys, apiLink);

      if (itemName === undefined) {
        if (Array.isArray(res)) {
          res.forEach((element) => {
            for (const item in element) {
              state[item] = element[item];
              // state[item].push({ id: 0, label: "-" });
            }
          });
        } else {
          for (const item in res) {
            state[item] = res[item];
          }
        }
      } else {
        // carica la lookup nello state con il nome passato da function
        state[itemName] = res;
      }
    },

    verifyLookup: async (stateObj, arrayLookupNames) => {
      const { state } = stateObj;
      arrayLookupNames.forEach(async (lookupName) => {
        if (state[`lookup_${lookupName}`] == undefined) {
          if (state[`isLoadinglookup_${lookupName}`] == true) {
            return;
          }
          state[`isLoadinglookup_${lookupName}`] = true;
          // lookupToFetch =
          //   lookupToFetch +
          //   `${lookupToFetch == "" ? lookupName : `,${lookupName}`}`;
          let res;
          if (lookupName === "regioni") {
            res = await getLookupRegioniProvincie();
            state[`lookup_${lookupName}`] = res;
          } else if (lookupName === "giorniSettimana") {
            res = await getLookupGiornoDisponibili();
            state[`lookup_${lookupName}`] = res;
          } else if (lookupName === "fasi") {
            res = await getLookupFasi();
            state[`lookup_${lookupName}`] = res;
          } else if (lookupName === "ruoli") {
            res = await getLookupRuoli();
            state[`lookup_${lookupName}`] = res;
          } else {
            res = await getlookupFromApi({ keys: lookupName });
            res.forEach((lookup) => {
              state[`lookup_${lookupName}`] = Object.values(lookup)[0];
            });
          }
          state[`isLoadinglookup_${lookupName}`] = false;
        }
      });
    },
  },
  mutations: {
    modifyStoreDataFromName: (state, name) => {
      state[name].push({ id: "T", nome: "Test" });
    },
    assignStateValue: (state, { value, stateName }) => {
      if (stateName.includes("lookup_province")) {
        state[stateName] = [];
        value.forEach((element) => {
          state[stateName].push(element);
        });
      } else {
        state[stateName] = value;
      }
    },
    spliceArrayFromName: (state, stateName) => {
      if (state[stateName] === undefined) return;
      state[stateName].splice(0, state[stateName].length);
    },
    resetObjectFromName: (state, stateName) => {
      if (state[stateName] === undefined) return;
      state[stateName] = null;
    },

    setFetchRowsListFromName: (state, { itemName, value }) => {
      state[`currentPage${itemName}`] = 1;
      state[`rowstoskip${itemName}`] = 0;
      state[`fetchrows${itemName}`] = value;
    },
    setCurrentPageListFromName: (state, { itemName, value }) => {
      state[`currentPage${itemName}`] = value;
      state[`rowstoskip${itemName}`] = parseInt(
        (state[`currentPage${itemName}`] - 1) * state[`fetchrows${itemName}`]
      );
    },
    setSortColumnListFromName: (state, { itemName, value }) => {
      state[`sortcolumn${itemName}`] = value;
    },
    setSortOrderListFromName: (state, { itemName, value }) => {
      state[`sortorder${itemName}`] = value;
    },
    resetListParameters: (
      state,
      { itemName, staticFilters, sortcolumn, sortorder }
    ) => {
      state[`currentPage${itemName}`] = 1;
      state[`rowstoskip${itemName}`] = 0;
      state[`fetchrows${itemName}`] = 20;
      state[`sortcolumn${itemName}`] = sortcolumn;
      state[`sortorder${itemName}`] = sortorder;
      staticFilters.forEach((staticFilter) => {
        state[staticFilter] = undefined;
      });
    },
  },
};
